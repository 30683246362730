import { reactive, computed, ref } from '@vue/composition-api'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import find from 'lodash/find'
import map from 'lodash/map'

import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'

import env from '@/env'

import moment from 'moment'

/* eslint-disable-next-line */
export default (ctx) => {
  // TODO: Don't forget to clean form data detail
  const lifeTimeDuration = env.VUE_APP_PROJECT_HOUSE_SELL_LIFE_TIME_DURATION
  const lifeTimeUnit = env.VUE_APP_PROJECT_HOUSE_SELL_LIFE_TIME_UNIT

  const showSoldOutTime = env.VUE_APP_PROJECT_HOUSE_SELL_LIFE_SOLD_OUT_SHOW_TIME_DURATION
  const showSoldOutUnit = env.VUE_APP_PROJECT_HOUSE_SELL_LIFE_SOLD_OUT_SHOW_TIME_UNIT

  const formData = reactive({
    'id': null,
    'uuid': null,
    'status': true,
    'is_default': false,
    'is_sold_out': false,
    'house_model': null,
    'house_model_id': null,
    'show_list_page': false, // ถ้าเป็นบ้านพร้อมตกแต่ง -> true, false
    'project_house_sell_image_type': 'public', // Gallery บ้านพร้อมขาย -> "self", "public"
    'project_house_sell_number': null, // หมายเลขแปลง
    'project_house_sell_highlight': '', // จุดเด่น
    'project_house_sell_building_space': '', // สร้างบนพื้นที่ตั้งแต่
    'project_house_sell_icon_activity': null, // activity
    'project_house_sell_icon_activity_id': null, // activity
    'project_house_sell_gallery': null,
    'project_house_sell_gallery_id': null,
    'project_house_sell_gallery_by_project_selected': [],
    'project_house_sell_gallery_selected': null,
    'project_house_sell_self_gallery_selected': [],
    'project_house_sell_model_gallery_selected': [],
    'project_house_sell_area': null, // ขนาดที่ดิน
    'project_house_sell_summary_price': '', //ราคารวมสุทธิ
    'project_house_sell_down_percent': null, // ดาวน์
    'project_house_sell_down_price': '', // เป็นเงิน
    'project_house_sell_reserve_price': '', //เงินจอง
    'project_house_sell_contract_price': '', // ทำสัญญา
    'project_house_sell_transfer_price': '', // เงินโอน
    'project_house_sell_installment_price': '', // ผ่อนเริ่มต้น
    'project_house_sell_installment_period': null, // ดอกเบี้ย *
    'project_house_sell_interest_rate': '', // ระยะเวลาผ่อนชำระ *
    'project_house_sell_links': {
      'project_uuid': null,
    },
    'project_house_sell_remark': null,
    'project_house_sell_features_selected': [], 
    'project_house_sell_features': [
      // {
      //   'house_function_feature_id': null,
      //   'value': false, // true คือ select, false คือไม่ select
      // },
    ],
    'project_house_sell_files': [
      // {
      //   'is_default': false,
      //   'image': { 'public_id': null },
      //   "attribute_code": "house_model_image" // ภาพแบบบ้าน  (เลือกรูปแบบบ้านหลัก) ภาพจาก house model
        // "attribute_code": "project_house_sell_gallery" // ถ้ามาเลือก บ้านพร้อมตกแต่งพร้อมขาย
        // "attribute_code": "project_house_sell_self_image" ถ้ามาเลือก อัปโหลดรูปเอง
        // "attribute_code": "project_house_sell_public_image" ถ้ามาเลือก รูปจากส่วนกลาง (Gallery แบบบ้าน)
      // }
    ],
    'project_house_sell_folder_name': '',
    'project_house_sell_start_date': moment().format(),
    'project_house_sell_end_date': moment().add(lifeTimeDuration, lifeTimeUnit).format(),
    'project_house_sell_sold_out_end_date': null,
    'can_edit_sold_out': true,
    'can_edit_show_list_page': true
  })

  // use to check is initial is furnished houes
  const isFurnishedHouse = ref(false)
  
  const prepareFormData = () => {
  }

  const fetchByRouteId = () => {
  }

  const user = ctx.root.$store.state.AppActiveUser

  const onload = async () => {
    const folders = ['Gallery']

    await ctx.root.$vs.loading()
    await ctx.root.$store.dispatch('project/fetchItem', ctx.root.$route.params.project_id)
    await ctx.root.$store.dispatch('houseModel/fetch')
    // await ctx.root.$store.dispatch('houseFunction/fetch')
    await ctx.root.$store.dispatch('houseFunctionFeature/fetch')
    await ctx.root.$store.dispatch('houseIconActivity/fetch')
    await ctx.root.$store.dispatch('houseSellGallery/fetchByProjectUuid', get(ctx.root.$store.state.project, 'project.uuid'))
    await ctx.root.$store.dispatch('houseFunction/fetchByLang', 'th')

    if (ctx.root.$route.params.uuid) {
      // console.log(ctx.root.$route.params.uuid, 'project uuid')
      await ctx.root.$store.dispatch('projectHouseSell/fetchOne', ctx.root.$route.params.uuid)
        .then(async (response) => {
          const lang = ctx.root.$store.state.locale.currentLang
          Object.assign(formData, response[lang])

          formData.can_edit_sold_out = !response.is_sold_out

          formData.status = response.status === 'published'

          formData.uuid = ctx.root.$route.params.uuid
          formData.is_default = response.is_default
          formData.is_sold_out = response.is_sold_out
          
          formData.created_by = response.created_by
          formData.updated_by = response.updated_by
          formData.updated_at = response.updated_at

          formData.house_model = {
            label: get(response, `house_model.${lang}.house_model_title`),
            value: get(response, 'house_model.uuid')
          }
          formData.house_model_id = get(response, 'house_model.uuid')
          const houseSellSelfFile = get(response, 'project_house_sell_files', [])
            .filter(v => v.attribute_code === 'project_house_sell_self_image')

          formData.project_house_sell_self_gallery_selected = orderBy(houseSellSelfFile, ['image_index'], ['asc'])
          formData.project_house_sell_gallery_selected = get(
            get(response, 'project_house_sell_files', [])
              .find(v => v.attribute_code === 'house_model_image'),
            'uuid'
            , null
          )


          const iconSelected = get(ctx.root.$store.state.houseIconActivity, 'items')
            .find(v => v.id === parseInt(formData.project_house_sell_icon_activity_id))
            if (!isEmpty(iconSelected)) {
              formData.project_house_sell_icon_activity = {
                label: get(iconSelected, 'house_icon_activity_title', null),
                value: get(iconSelected, 'id', null),
                image: get(iconSelected, 'house_icon_activity_files.0.image.0'),
              }
            }
          formData.project_house_sell_features_selected = get(response, 'project_house_sell_features', [])
            .map(v => v.house_function_feature_id)

          const houseModelGallery = get(response, 'project_house_sell_files', [])
          .filter(v => v.attribute_code === 'project_house_sell_public_image')
          .map(v => get(v, 'uuid')) 
          
          formData.project_house_sell_model_gallery_selected = houseModelGallery
          formData.show_list_page = get(response, `${lang}.show_list_page`) === 'true'

          formData.can_edit_show_list_page = !formData.show_list_page

          if (get(response, `${lang}.project_house_sell_gallery_id`)) {
            const projectHouseSellGallerSelected = get(ctx.root.$store.state.houseSellGallery, 'filter_items')
              .find(v => {
                return v.id === parseInt(get(response, `${lang}.project_house_sell_gallery_id`))
              })

              if (projectHouseSellGallerSelected) {
                formData.project_house_sell_gallery = {
                  label: projectHouseSellGallerSelected.name,
                  value: projectHouseSellGallerSelected.id,
                  house_sell_gallery_files: projectHouseSellGallerSelected.house_sell_gallery_files
                }

                if (projectHouseSellGallerSelected.house_sell_gallery_files.length > 0) {
                  const houseSellFiles = get(response, 'project_house_sell_files', [])
                    .filter(v => v.attribute_code === 'project_house_sell_gallery')
                    .map(v => get(v, ['image_uuid']))
                  formData.project_house_sell_gallery_by_project_selected = houseSellFiles
                }
              } else {
                formData.project_house_sell_gallery = {}
              }
          }

          const path = `project/${ctx.root.$route.params.project_id}/project-house-sell`
          if (!formData.project_house_sell_folder_name) {
            const randomUuid = uuidv4()
            formData.project_house_sell_folder_name = randomUuid
          }
          await createFolder(`${path}/${formData.project_house_sell_folder_name}`, folders)

          if (!formData.project_house_sell_start_date) {
            formData.project_house_sell_start_date = moment().format()
          }
          if (!formData.project_house_sell_end_date) {
            formData.project_house_sell_end_date = moment().add(lifeTimeDuration, lifeTimeUnit).format()
          }

          if (formData.is_sold_out && !formData.project_house_sell_sold_out_end_date) {
            formData.project_house_sell_sold_out_end_date = moment().add(showSoldOutTime, showSoldOutUnit).format()
          }
        })
    } else {
      const randomUuid = uuidv4()
      formData.project_house_sell_folder_name = randomUuid
      const path = `project/${ctx.root.$route.params.project_id}/project-house-sell`
      await createFolder(`${path}/${randomUuid}`, folders)
    }

    isFurnishedHouse.value = formData.show_list_page

    await ctx.root.$vs.loading.close()
  }

  const buildProjectHouseSellFile = (item, attribute_code) => {
    return {
      ...item,
      attribute_code
    }
  }

  const houseSellGallery = computed(() => {
    return get(ctx.root.$store.state.houseModel.item, 'house_model_files', []).filter(
      (v) => v.attribute_code === 'house_model_image',
    )
  })

  const houseModelPublicGallery = computed(() => {
    return get(ctx.root.$store.state.houseModel.item, 'house_model_files', []).filter(
      (v) => v.attribute_code === 'house_model_gallery',
    )
  })

  const buildPayload = () => {
    const houseModelGallerySelected = find(
      houseSellGallery.value,
      (item) => item.uuid === formData.project_house_sell_gallery_selected,
    )

    if (houseModelGallerySelected) {
      formData.project_house_sell_files.push(
        buildProjectHouseSellFile(houseModelGallerySelected, 'house_model_image'),
      )
    }

    if (
      !formData.show_list_page &&
      formData.project_house_sell_image_type === 'self'
    ) {
      const mapSelfImages = map(
        formData.project_house_sell_self_gallery_selected,
        (item) => buildProjectHouseSellFile(item, 'project_house_sell_self_image'),
      )

      if (mapSelfImages && mapSelfImages.length > 0) {
        mapSelfImages.forEach((item) =>
          formData.project_house_sell_files.push(item),
        )
      }
    }

    if (formData.show_list_page 
      && formData.project_house_sell_gallery_by_project_selected.length > 0) {
      formData.project_house_sell_gallery_by_project_selected.forEach(valueSelected => {
        const itemSelected = get(formData.project_house_sell_gallery, 'house_sell_gallery_files').find(v => {
          return v.image_uuid === valueSelected              
        })
        if (itemSelected) {
          itemSelected.attribute_code = 'project_house_sell_gallery'
          formData.project_house_sell_files.push(itemSelected)
        }
      })
    }

    if (formData.project_house_sell_features_selected.length > 0) {
      formData.project_house_sell_features = formData.project_house_sell_features_selected
        .map(v => ({
          house_function_feature_id: v,
          value: true
        }))
    }

    if (formData.project_house_sell_model_gallery_selected.length > 0) {
      formData.project_house_sell_model_gallery_selected.forEach(valueSelected => {
        const houseModelGalleryFile = houseModelPublicGallery.value.find(v => {
            return v.uuid === valueSelected              
          })
        if (houseModelGalleryFile) {
          houseModelGalleryFile.attribute_code = 'project_house_sell_public_image'
          formData.project_house_sell_files.push(houseModelGalleryFile)
        }
      })
    }

    formData.project_house_sell_links = {
      project_uuid: get(ctx.root.$store.state.project, 'project.uuid'),
    }

    const payload = omit(formData, [
      'house_model',
      'updated_at',
      'project_house_sell_gallery_selected',
      'project_house_sell_self_gallery_selected',
      'project_house_sell_model_gallery_selected',
    ])

    payload.status = formData.status ? 'published' : 'drafted'
    if (!formData.status) payload.is_default = false

    // lasted user update
    payload.updated_by = user.email

    // user created by
    payload.created_by = formData.created_by ? user.email : formData.created_by

    payload.project_house_sell_end_date = moment(payload.project_house_sell_end_date).format()

    return payload
  }

  const pageTitle = computed(() => {
    const project = get(ctx, ['root', '$store', 'state', 'project', 'project'], null)
    const lang = ctx.root.$store.state.locale.currentLang
    const title = get(project, [lang, 'title'], '')

    return ctx.root.$route.params.uuid
      ? `แก้ไขข้อมูลบ้านพร้อมขาย โครงการ : ${title}`
      : `สร้างข้อมูลบ้านพร้อมขาย โครงการ : ${title}`
  })

  return {
    formData,
    buildProjectHouseSellFile,
    prepareFormData,
    fetchByRouteId,
    onload,
    buildPayload,
    pageTitle,
    isFurnishedHouse
  }
}
