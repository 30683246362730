<template>
  <div>
    <FormLayout>
      <FormLabel> เลือกชื่อแบบบ้าน <span class="text-danger">*</span> </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="เลือกชื่อแบบบ้าน"
          rules="required"
        >
          <v-select
            v-model="$attrs.value.house_model"
            :options="modelList"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            placeholder="เลือกชื่อแบบบ้าน"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        หมายเลขแปลง
        <span class="text-danger">*</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="หมายเลขแปลง"
          rules="required"
        >
          <vs-input
            :disabled="isEditMode"
            v-model="$attrs.value.project_house_sell_number"
            name="project_house_sell_number"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
     <FormLayout>
      <FormLabel>
         กำหนดสถานะ sold out
        <br >
      </FormLabel>
      <FormContent>
        <vs-checkbox
          class="mt-2"
          v-model="$attrs.value.is_sold_out"
          name="project_house_sell_files_check_box"
        >
          <span v-if="$attrs.value.is_sold_out" class="text-warning">แสดงบนหน้าเว็บไซต์ถึงวันที่ {{showInWebsiteDate}}</span>
        </vs-checkbox>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        ระบุลักษณะเด่นของแปลง <span class="text-danger">*</span> <br >
        <span>(ไม่เกิน 250 ตัวอักษร)</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="ระบุลักษณะเด่นของแปลง"
          rules="required"
        >
          <vs-textarea
            v-model="$attrs.value.project_house_sell_highlight"
            name="project_house_sell_highlight"
            counter="250"
            maxlength="250"
            :rows="6"
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        สร้างบนพื้นที่ตั้งแต่ <span class="text-danger">*</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="สร้างบนพื้นที่ตั้งแต่"
          rules="required"
        >
          <div class="flex space-x-3 items-center">
            <lh-input-number
              class="w-3/12"
              v-model="$attrs.value.project_house_sell_building_space"
              name="project_house_sell_building_space"
            />
            <span>ตารางวา</span>
          </div>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        พื้นที่ใช้สอย
      </FormLabel>
      <FormContent class="flex space-x-3 items-center">
        <vs-input 
          class="w-3/12"
          :value="houseModelArea" 
          disabled 
          readonly />
        <span>ตารางเมตร</span>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        ระบุไอคอนกิจกรรม (ถ้ามี)
      </FormLabel>
      
      <FormContent class="flex flex-col space-y-3">
        <v-select
          v-model="$attrs.value.project_house_sell_icon_activity"
          :options="houseIconActivityList"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          placeholder="เลือกไอคอน"
        />

        <vx-card>
          <img
            v-if="
              $attrs.value.project_house_sell_icon_activity &&
                $attrs.value.project_house_sell_icon_activity.image
            "
            :src="getImageUrl($attrs.value.project_house_sell_icon_activity.image.public_id)"
            :alt="`image-activity`"
            class="border-2 border-danger"
          >
        </vx-card>
      </FormContent>
    </FormLayout>
  </div>
</template>

<script>

import { computed, watch } from '@vue/composition-api'
import VSelect from 'vue-select'
import moment from 'moment'
import env from '@/env'
import { getImageUrl } from '@/helper/Utils'

export default {
    name: 'BasicInfoForm',
    components: {
        VSelect,
    },
    props: {
        houseModelArea: {
            type: String,
            required: true
        },
        modelList: {
            type: Array,
            required: true
        },
        houseIconActivityList: {
            type: Array,
            required: true
        }
    },
    setup(props, ctx) {

      const isEditor = computed(() => ctx.root.$acl.check('editor'))
      const isEditMode = computed(() => ctx.root.$route.params.uuid)

      const lifeTimeDuration = env.VUE_APP_PROJECT_HOUSE_SELL_LIFE_TIME_DURATION
      const lifeTimeUnit = env.VUE_APP_PROJECT_HOUSE_SELL_LIFE_TIME_UNIT

      const showSoldOutTime = env.VUE_APP_PROJECT_HOUSE_SELL_LIFE_SOLD_OUT_SHOW_TIME_DURATION
      const showSoldOutUnit = env.VUE_APP_PROJECT_HOUSE_SELL_LIFE_SOLD_OUT_SHOW_TIME_UNIT

      const maxEndDate = computed(() => {
        const max = moment(ctx.attrs.value.project_house_sell_start_date).add(lifeTimeDuration, lifeTimeUnit).format()
        const min = moment(ctx.attrs.value.project_house_sell_start_date).subtract(1, 'd').format()
        
        return { to: new Date(min), from: new Date(max) }
      })

      const showInWebsiteDate = computed(() => {
        moment.locale('th')
        const date = moment(ctx.attrs.value.project_house_sell_sold_out_end_date).format('LL')
        return date 
      })
      
      watch(
        () => ctx.attrs.value.is_sold_out,
        () => {
          if (ctx.attrs.value.is_sold_out && !ctx.attrs.value.project_house_sell_sold_out_end_date) {
            ctx.attrs.value.project_house_sell_sold_out_end_date = moment().add(showSoldOutTime, showSoldOutUnit).format()
          } else if (!ctx.attrs.value.is_sold_out) {
            ctx.attrs.value.project_house_sell_sold_out_end_date = null
          }
        }
      )

      return {
        isEditor,
        isEditMode,
        maxEndDate,
        showInWebsiteDate,
        getImageUrl
      }
    }
}
</script>
