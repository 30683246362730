<template>
  <div>
    <Draggable
      :list="$attrs.value"
      :group="selectionName"
      :class="`grid grid-cols-4 gap-6 cursor-move`"
      v-bind="{
        animation: 200,
        disabled: false,
      }"
    >
      <div 
        v-for="(item, index) in $attrs.value" 
        :key="index">
        <GalleryItemForm
          v-model="$attrs.value[index]"
          :key-component="index"
          :col="col"
          :has-alt-text="hasAltText"
          :has-detail="hasDetail"
          :has-description="hasDescription"
          :has-ex-url="hasExUrl"
          :has-date="hasDate"
          :has-index="hasIndex"
          :has-selectable="hasSelectable"
          :select-radio-label="selectRadioLabel"
          :item-no="index"
          @edit-item="editItem(item)"
          @delete-item="deleteItem(item)"
          @on-selected="handleSelected(index)"
        />
      </div>
    </Draggable>
    <div class="mt-8">
      <MediaWidgetInit
        :folder="folder"
        :resource-type="resourceType"
        :multiple="true"
        :selection-name="selectionName"
        @selected-value="onSelectedValue"
        @open-widget="openWidget"
      >
        {{ buttonLabel }}
      </MediaWidgetInit>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { watch } from '@vue/composition-api'
import MediaWidgetInit from '@/components/MediaWidgetInit'
import GalleryItemForm from '@/components/GalleryItemForm'
import useGalleryForm from '@/use/useGalleryForm'
import Draggable from 'vuedraggable'
import env from '@/env'

export default {
  name: 'GalleryForm',
  components: { Draggable, GalleryItemForm, MediaWidgetInit },
  props: {
    folder: {
      type: String,
      default: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/gallery`,
    },
    resourceType: {
      type: String,
      default: 'image',
    },
    buttonLabel: {
      type: String,
      default: 'Select File',
    },
    col: {
      type: Number,
      default: 3,
    },
    hasAltText: {
      type: Boolean,
      default: false,
    },
    hasDetail: {
      type: Boolean,
      default: false,
    },
    hasDescription: {
      type: Boolean,
      default: false,
    },
    hasExUrl: {
      type: Boolean,
      default: false,
    },
    hasDate: {
      type: Boolean,
      default: false,
    },
    hasSelectable: {
      type: Boolean,
      default: false,
    },
    hasIndex: {
      type: Boolean,
      default: false,
    },
    selectionName: {
      type: String,
      required: true,
      // default: 'gallery-selection-name'
    },
    selectRadioLabel: {
      type: String,
      default: 'เลือก'
    }
  },
  setup(props, ctx) {
    let galleryForm = useGalleryForm(ctx, props)
    watch(
      () => ctx.attrs.value,
      () => {
        galleryForm = useGalleryForm(ctx, props)
      },
    )

    const openWidget = () => {
      galleryForm.indexOfItemEdit.value = -1
    }

    const onSelectedValue = (item) => {
      galleryForm.onSelectedValue(item)
    }

    const editItem = (item) => {
      ctx.root.$store.dispatch('cld/setSelectionName', props.selectionName)
      galleryForm.editItem(item)
    }

    const deleteItem = (item) => {
      galleryForm.deleteItem(item)
    }

    const handleSelected = (index) => {
      ctx.attrs.value.forEach((item) => {
        item.image_selected = false
      })

      Vue.set(ctx.attrs.value, index, {
        ...ctx.attrs.value[index],
        image_selected: true,
      })
    }

    return {
      ...galleryForm,
      handleSelected,
      onSelectedValue,
      editItem,
      deleteItem,
      openWidget,
    }
  },
}
</script>
