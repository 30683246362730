/* eslint-disable */
import Vue from 'vue'
import { ref, watch } from '@vue/composition-api'

export default (ctx, props) => {
  const { attrs } = ctx
  const indexOfItemEdit = ref(-1)

  const onSelectedValue = (value) => {
    // console.log(value, props.selectionName, '<<')
    if (indexOfItemEdit.value >= 0 && value.length > 0) {
      Vue.set(attrs.value, indexOfItemEdit.value, {
        ...value[0],
        image_alt_text: attrs.value[indexOfItemEdit.value].image_alt_text,
        image_detail: attrs.value[indexOfItemEdit.value].image_detail,
        image_description: attrs.value[indexOfItemEdit.value].image_description,
        image_ex_url: attrs.value[indexOfItemEdit.value].image_ex_url,
        image_start_date: attrs.value[indexOfItemEdit.value].image_start_date,
        image_end_date: attrs.value[indexOfItemEdit.value].image_end_date,
        image_selected: attrs.value[indexOfItemEdit.value].image_selected,
      })
      indexOfItemEdit.value = -1
    } else {
      value.forEach((item) => {
        attrs.value.push({
          ...item,
          image_alt_text: null,
          image_detail: null,
          image_description: null,
          image_ex_url: null,
          image_start_date: null,
          image_end_date: null,
          image_selected: false,
        })
      })
    }
  }

  const editItem = (item) => {
    indexOfItemEdit.value = attrs.value.indexOf(item)
    window.ml.show({
      folder: {
        path: props.folder,
        resource_type: props.resourceType,
      },
      multiple: false,
    })
  }

  const deleteItem = (item) => {
    console.log('delete', attrs.value, item, attrs.value.indexOf(item), ctx.root)
    Vue.delete(attrs.value, attrs.value.indexOf(item))
  }

  return {
    indexOfItemEdit,
    onSelectedValue,
    editItem,
    deleteItem,
  }
}
