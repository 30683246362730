<template>
  <div>
    <FormLayout>
      <FormLabel>
        ภาพ Floor Plan ชั้น {{ content.no }}
      </FormLabel>
      <FormContent>
        <vx-card
          :style="{
            minHeight: '150px'
          }"
        >
          <img
            :src="getImageUrl(content.image.public_id)"
            alt="house-series-logo"
            class="rounded w-full"
          >
        </vx-card>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        รายละเอียด ชั้น {{ content.no }}
      </FormLabel>
      <FormContent>
        <vs-textarea 
          class="disable-area"
          :value="content.description" 
          :rows="8"
          disabled />
      </FormContent>
    </FormLayout>
  </div>
</template>

<script>
import { getImageUrl } from '@/helper/Utils'
  export default {
    name: 'HouseSellFloorForm',
    props: {
      content: {
        type: Object,
        required: true
      }
    },
    setup() {
      return getImageUrl
    }
  }
</script>

<style scoped>
  .disable-area {
    background-color: #efefef !important;
    border-color: #dcdcdc !important;
  }
</style>
