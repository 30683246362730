<template>
  <div class="vx-row text-base">
    <div class="vx-col w-full mb-base">
      <vx-card
        :title="pageTitle"
        class="body-content relative pt-8"
      >
        <div v-if="isExpired" class="absolute h-full w-full top-0 left-0 bg-white" style="z-index: 10000; opacity: .45">
          <p class="text-danger text-2xl p-4 text-right">ไม่สามารถแก้ไขข้อมูลได้</p>
        </div>
        <ValidationObserver v-slot="{ validate }">
          <form>
            <BasicInfoForm 
              v-model="formData" 
              :model-list="modelList" 
              :house-model-area="houseModelArea || ''"
              :house-icon-activity-list="houseIconActivityList" /> 
            <vs-divider />
            <HouseFunctionForm 
              v-model="formData" 
              :house-feature-function-list="houseFeatureFunctionList" 
              :house-series-logo="houseSeriesLogo || {}"
              :house-sell-gallery="houseSellGallery"
              :house-floors="houseFloors" />
            <vs-divider />
            <FormLayout>
              <FormLabel>
                บ้านตัวอย่างสร้างใหม่พร้อมขาย
                <br >(หากไม่มีรุปกรุณา ติกออก)
              </FormLabel>
              <FormContent>
                <!-- <vs-checkbox
                  :disabled="!formData.can_edit_show_list_page && !isEditor"
                  v-model="formData.show_list_page"
                  name="project_house_sell_files_check_box"
                > -->
                <vs-checkbox
                  v-model="formData.show_list_page"
                  name="project_house_sell_files_check_box"
                >
                  Yes
                </vs-checkbox>
              </FormContent>
            </FormLayout>
            <FormLayout v-if="formData.show_list_page">
              <FormLabel>
                รูป Gallery
              </FormLabel>
              <FormContent>
                <v-select
                  v-model="formData.project_house_sell_gallery"
                  :options="houseSellGalleryList"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  placeholder="เลือก Folder"
                  @input="setSelected"
                />
              </FormContent>
            </FormLayout>
            <FormLayout v-if="formData.show_list_page">
              <FormLabel>
                ภาพ Gallery บ้านพร้อมตกแต่ง
              </FormLabel>
              <div class="vx-col w-full md:w-9/12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="formData.show_list_page ? 'required' : ''"
                  name="ภาพ Gallery บ้านพร้อมตกแต่ง"
                >
                  <vx-card
                    :style="{
                      minHeight: '150px',
                    }"
                  >
                    <div class="grid grid-cols-4 gap-3">
                      <div
                        v-for="(item, index) in houseSellGalleryByProject"
                        :key="index"
                        class="flex flex-col w-full"
                      >
                        <div class="mb-4">
                          <img
                            :src="getImageUrl(item.public_id)"
                            alt="house-series-logo"
                            class="rounded w-full"
                          >
                        </div>
                        <vs-checkbox
                          v-model="formData.project_house_sell_gallery_by_project_selected"
                          :vs-value="item.image_uuid"
                          name="project_house_sell_gallery_selected"
                        >
                          เลือก <span class="text-primary">{{ formData.project_house_sell_gallery_by_project_selected.indexOf(item.image_uuid) >= 0 
                          ? formData.project_house_sell_gallery_by_project_selected.indexOf(item.image_uuid) + 1 : "" }}</span>
                        </vs-checkbox>
                      </div>
                    </div>
                  </vx-card>
                  <span 
                    v-show="errors.length > 0" 
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </FormLayout>
            <FormLayout v-if="!formData.show_list_page">
              <FormLabel>
                Gallery บ้านพร้อมขาย
              </FormLabel>
              <FormContent class="flex space-x-3">
                <vs-radio
                  v-model="formData.project_house_sell_image_type"
                  vs-value="self"
                >
                  อัปโหลดรูปเอง
                </vs-radio>
                <vs-radio
                  v-model="formData.project_house_sell_image_type"
                  vs-value="public"
                >
                  รูปจากส่วนกลาง (Gallery แบบบ้าน)
                </vs-radio>
              </FormContent>
            </FormLayout>
            <FormLayout
              class="flex justify-center"
              v-if="
                formData.project_house_sell_image_type === 'self' &&
                  !formData.show_list_page
              "
            >
              <GalleryForm
                class="w-10/12"
                :col="3"
                v-model="formData.project_house_sell_self_gallery_selected"
                :folder="`${baseFolder}/project/${projectId}/project-house-sell/${formData.project_house_sell_folder_name}/Gallery`"
                has-alt-text
                has-detail
                selection-name="project_house_sell_image_type"
              />
            </FormLayout>
            <FormLayout
              v-if="
                formData.project_house_sell_image_type === 'public' &&
                  !formData.show_list_page
              "
            >
              <FormLabel>
                ภาพ galery บ้านพร้อมขาย
              </FormLabel>
              <div class="vx-col w-full md:w-9/12">
                <vx-card
                  :style="{
                    minHeight: '150px',
                  }"
                >
                  <div
                    v-if="houseModelPublicGallery.length > 0"
                    class="grid grid-cols-4 gap-3"
                  >
                    <div
                      v-for="(item, index) in houseModelPublicGallery"
                      :key="index"
                      class="flex flex-col w-full"
                    >
                      <div class="mb-4">
                        <img
                          :src="getImageUrl(item.public_id)"
                          alt="house-series-logo"
                          class="rounded w-full"
                        >
                      </div>
                      <vs-checkbox
                        v-model="formData.project_house_sell_model_gallery_selected"
                        :vs-value="item.uuid"
                        name=""
                      >
                        เลือก
                      </vs-checkbox>
                    </div>
                  </div>
                  <div v-else>
                    ไม่มีรูปให้เลือก
                  </div>
                </vx-card>
              </div>
            </FormLayout>
            <vs-divider />
            <h4 class="pb-8">ตารางราคาและเงื่อนไขการผ่อน</h4>
            <FormLayout>
              <FormLabel> ขนาดที่ดิน <span class="text-danger">*</span> </FormLabel>
              <FormContent>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="ขนาดที่ดิน"
                  rules="required"
                >
                  <div class="flex space-x-3 items-center">
                    <lh-input-number class="w-4/12" v-model="formData.project_house_sell_area" />
                    <span>ตารางวา</span>
                  </div>
                  <span 
                    v-show="errors.length > 0" 
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel> ราคารวมสุทธิ <span class="text-danger">*</span> </FormLabel>
              <FormContent>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="ราคารวมสุทธิ"
                  rules="required"
                >
                  <div class="flex space-x-3 items-center">
                    <lh-input-number class="w-4/12" v-model="formData.project_house_sell_summary_price" />
                    <span>บาท</span>
                  </div>
                  <span 
                    v-show="errors.length > 0" 
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                ดาวน์
              </FormLabel>
              <FormContent class="flex space-x-3 items-center">
                <lh-input-number class="w-4/12" v-model="formData.project_house_sell_down_percent" />
                <span class="w-1/12">%</span>
                <span class="w-1/12 text-right">เป็นเงิน</span>
                <lh-input-number class="w-4/12" v-model="formData.project_house_sell_down_price" />
                <span>บาท</span>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                เงินจอง
              </FormLabel>
              <FormContent class="flex space-x-3 items-center">
                <lh-input-number class="w-4/12" v-model="formData.project_house_sell_reserve_price" />
                <span>บาท</span>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                ทำสัญญา
              </FormLabel>
              <FormContent class="flex space-x-3 items-center">
                <lh-input-number class="w-4/12" v-model="formData.project_house_sell_contract_price" />
                <span>บาท</span>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel> เงินโอน <span class="text-danger">*</span> </FormLabel>
              <FormContent>
                <ValidationProvider 
                  v-slot="{ errors }" 
                  name="เงินโอน" 
                  rules="required">
                  <div class="flex space-x-3 items-center">
                    <lh-input-number class="w-4/12" v-model="formData.project_house_sell_transfer_price" />
                    <span>บาท</span>
                  </div>
                  <span 
                    v-show="errors.length > 0" 
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel>ผ่อนเริ่มต้น <span class="text-danger">*</span> </FormLabel>
              <FormContent>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="ผ่อนเริ่มต้น"
                  rules="required"
                >
                  <div class="flex space-x-3 items-center">
                    <lh-input-number class="w-4/12" v-model="formData.project_house_sell_installment_price" />
                    <span>ต่อเดือน</span>
                  </div>
                  <span 
                    v-show="errors.length > 0" 
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel> ดอกเบี้ย <span class="text-danger">*</span> </FormLabel>
              <FormContent>
                <ValidationProvider 
                  v-slot="{ errors }" 
                  name="ดอกเบี้ย" 
                  rules="required">
                  <div class="flex space-x-3 items-center">
                    <lh-input-number class="w-4/12" v-model="formData.project_house_sell_interest_rate" />
                    <span>%</span>
                  </div>
                  <span 
                    v-show="errors.length > 0" 
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel> ระยะเวลาผ่อนชำระ <span class="text-danger">*</span> </FormLabel>
              <FormContent>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="ระยะเวลาผ่อนชำระ"
                  rules="required"
                >
                  <div class="flex space-x-3 items-center">
                    <lh-input-number
                      v-model="formData.project_house_sell_installment_period"
                      class="w-4/12"
                    />
                    <span class="w-2/12">ปี</span>
                  </div>
                  <span 
                    v-show="errors.length > 0" 
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                เงื่อนไขการผ่อนพิเศษ
              </FormLabel>
              <FormContent class="flex space-x-3 items-center">
                <vs-textarea 
                  :rows="6" 
                  v-model="formData.project_house_sell_remark" />
              </FormContent>
            </FormLayout>
            <!-- <vs-divider v-if="!formData.show_list_page"/> -->
            <!-- <FormLayout v-if="!formData.show_list_page">
              <FormLabel>
                <span>วันเริ่มต้นสร้างข้อมูล</span>
              </FormLabel>
              <FormContent>
                <datepicker 
                  class="w-4/12"
                  disabled
                  v-model="formData.project_house_sell_start_date"
                  :format="customFormatter" />
              </FormContent>
            </FormLayout>
            <FormLayout v-if="!formData.show_list_page">
              <FormLabel>
                <span>วันสิ้นสุดการแก้ไขข้อมูล</span><br>
                <span class="text-warning">*หากสิ้นสุดการแก้ไขข้อมูล บ้านพร้อมขายจะไม่แสดงที่หน้าเว็บไซต์</span>
              </FormLabel>
              <FormContent>
                <datepicker
                  class="w-4/12"
                  disabled
                  v-model="formData.project_house_sell_end_date"
                  :disabled-dates="maxEndDate"
                  :format="customFormatter" />
              </FormContent>
            </FormLayout> -->
            <vs-divider />
            <FormLayout>
              <FormLabel>
                <span>แสดงบนเว็ปไซต์ </span><span class="text-danger">*</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <vs-switch v-model="formData.status" />
              </div>
            </FormLayout>
            <FormLayout v-if="!formData.show_list_page">
              <FormLabel />
              <FormContent customClass="vx-col w-8/12">
                <span class="text-warning text-xl">ระยะเวลาการแสดงข้อมูลบ้านพร้อมขายบนเว็บไซต์ เริ่ม {{customFormatter(formData.project_house_sell_start_date)}} สิ้นสุด {{customFormatter(formData.project_house_sell_end_date)}} </span>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                <span>แก้ไขล่าสุดโดย</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <span class="mt-2"> {{formData.updated_by}}</span>
              </div>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                <span>แก้ไขล่าสุดวันที่</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
              </div>
            </FormLayout>
            <FormLayout class="mt-8">
              <FormLabel />
              <FormContent class="space-x-3">
                <vs-button 
                  v-if="$route.params.uuid" 
                  type="border"
                  color="warning"
                  @click="goToPreview"
                  style="z-index: 10001"
                >
                  Preview
                </vs-button>
                <vs-button v-if="!isExpired" @click="submit(validate)">
                  {{ $route.params.uuid ? 'Update' : 'Submit' }}
                </vs-button>
              </FormContent>
            </FormLayout>
          </form>
        </ValidationObserver>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { watch, computed, onMounted, onBeforeUnmount } from '@vue/composition-api'
import VSelect from 'vue-select'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'
// import omit from 'lodash/omit'
// import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import first from 'lodash/first'
import BasicInfoForm from './BasicInfoForm'
import HouseFunctionForm from './HouseFunctionForm'
import useCrud from '@/use/useCrud'
import useProjectHomeSellUtil from '@/use/useProjectHomeSellUtil'
import useNotify from '@/use/useNotify'
import GalleryForm from '@/components/GalleryForm'
import moment from 'moment'
import env from '@/env'
import { getImageUrl } from '@/helper/Utils'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'ProjectHomeSellForm',
  components: {
    BasicInfoForm,
    HouseFunctionForm,
    GalleryForm,
    VSelect,
    Datepicker
  },
  /* eslint-disable-next-line */
  setup(props, ctx) {
    const { formData, onload, buildPayload, pageTitle } = useProjectHomeSellUtil(ctx)
    const notifyFn = useNotify(ctx)
    const crudForm = useCrud(ctx, 'projectHouseSell', {
      fetchOnMount: false,
    })

    crudForm.routePrefix.value = 'property-asset-project-home-sell'

    const customFormatter = (date) => {
      return moment(date).format('DD/MM/YYYY')
    }

    watch(
      () => formData.house_model,
      (model, oldModel) => {
        if (model && model.value) {
          ctx.root.$vs.loading()
          ctx.root.$store
            .dispatch('houseModel/fetchOne', `${model.value}`)
            .then((response) => {
              formData.house_model_id = get(response, 'id')
              const gallery = first(
                  get(ctx.root.$store.state.houseModel.item, 'house_model_files', []).filter(
                    (v) => v.attribute_code === 'house_model_image',
                  )
                )

              if (gallery && gallery.uuid
                && (!formData.project_house_sell_gallery_selected || oldModel)
              ) {
                formData.project_house_sell_gallery_selected = gallery.uuid
              }

              ctx.root.$vs.loading.close()
            })
        }
      },
      {
        deep: true,
      },
    )

    watch(
      () => formData.project_house_sell_icon_activity,
      (activity) => {
        if (activity) {
          formData.project_house_sell_icon_activity_id = activity.value
        }
      },
      {
        deep: true,
      },
    )

    //project_house_sell_gallery_id
    watch(
      () => formData.project_house_sell_gallery,
      (activity) => {
        if (activity && activity.value) {
          formData.project_house_sell_gallery_id = activity.value
        }
      },
      {
        deep: true,
      },
    )

    const modelList = computed(() => {
      return map(ctx.root.$store.state.houseModel.items, (item) => {
        return {
          label: item.house_model_title,
          value: item.uuid,
        }
      })
    })

    const houseFunctionList = computed(() => {
      if (!ctx.root.$store.state.houseModel.items) {
        return []
      }

      return map(ctx.root.$store.state.houseModel.items, (item) => {
        return {
          label: item.house_model_title,
          value: item.house_model_uuid,
        }
      })
    })

    const houseFeatureFunctionList = computed(() => {
      // get title
      const currentLang = ctx.root.$store.state.locale.currentLang
      const listFunctions = get(ctx.root.$store.state.houseModel.item, 'house_model_functions', [])
      const langList = ctx.root.$store.state.houseFunction.langItems


      return listFunctions.map((item) => {
        const itemTh = langList.find((itemlangFunction) => {
          return itemlangFunction.id === item.house_function_id
        })

        let title = item.house_function_title
        let unit = item.house_function_unit
        if (currentLang !== 'th') {
          title = `${title} (${itemTh.house_function_title})`
          unit = `${unit} (${itemTh.house_function_unit})`
        } else {
          title = itemTh.house_function_title
          unit = itemTh.house_function_unit
        }

        return {
          ...item,
          house_function_title: title,
          house_function_unit: unit
        }
      })
    })

    const houseIconActivityList = computed(() => {
      const iconActivity = filter(ctx.root.$store.state.houseIconActivity.items, (item) => {
        const today = moment().format()
        const isValid = moment(today).isBetween(item.start_date, item.end_date, 'day', '[]')
        return isValid
      })

      iconActivity.unshift({
        house_icon_activity_title: "ไม่ระบุ",
        id: null,
        image: null
      })

      return map(iconActivity, (item) => {
        return {
          label: item.house_icon_activity_title,
          value: item.id,
          image: get(item, 'house_icon_activity_files.0.image.0'),
        }
      })
    })

    const houseIconActivitySelected = computed(() => {
      if (houseIconActivityList.value) {
        return houseIconActivityList.value.find(
          (v) => v.id === get(formData, 'project_house_sell_icon_activity_id.value'),
        )
      }

      return {}
    })

    const houseModelArea = computed(() => {
      const houseModel = ctx.root.$store.state.houseModel.item
      const lang = ctx.root.$store.state.locale.currentLang

      return get(houseModel, `${lang}.house_model_area`)
    })

    const houseSeriesLogo = computed(() => {
      return get(
        ctx.root.$store.state.houseModel.item,
        'house_serie.house_serie_files',
        [],
      ).find((v) => v.attribute_code === 'house_serie_logo')
    })

    const houseSellGallery = computed(() => {
      const images = get(ctx.root.$store.state.houseModel.item, 'house_model_files', []).filter(
        (v) => v.attribute_code === 'house_model_image',
      )
      
      return orderBy(images, ['image_index'], ['asc'])
    })

    const houseSellGalleryList = computed(() => {
      return ctx.root.$store.state.houseSellGallery.filter_items.map((v) => ({
        label: v.name,
        value: v.id,
        house_sell_gallery_files: v.house_sell_gallery_files
      }))
    })

    const houseSellGalleryByProject = computed(() => {
      if (isEmpty(formData.project_house_sell_gallery)) {
        return []
      }

      const images = get(formData.project_house_sell_gallery, 'house_sell_gallery_files')
      return orderBy(images, ['image_index'], ['asc'])
    })

    const houseModelPublicGallery = computed(() => {
      const images = get(ctx.root.$store.state.houseModel.item, 'house_model_files', []).filter(
        (v) => v.attribute_code === 'house_model_gallery',
      )
      return orderBy(images, ['image_index'], ['asc'])
    })

    const getHouseFloor = (floorNo) => {
      const image = get(
        ctx.root.$store.state.houseModel.item,
        'house_model_files',
        [],
      ).find((v) => v.attribute_code === `house_model_floor_${floorNo}_image`)
      const lang = ctx.root.$store.state.locale.currentLang
      const description = get(
        ctx.root.$store.state.houseModel.item,
        `${lang}.house_model_floor_${floorNo}_description`,
        ''
      )

      if (!image && !description) {
        return
      }

      return {
        no: get({ one: 1, two: 2, three: 3 }, floorNo),
        image,
        description,
      }
    }

    const houseFloors = computed(() => {
      const floors = []
      floors.push(getHouseFloor('one', 1))
      floors.push(getHouseFloor('two', 2))
      floors.push(getHouseFloor('three', 3))
      return floors.filter((v) => v)
    })

    const projectId = computed(() => {
      return ctx.root.$route.params.project_id
    })

    const getHouseFunction = (value, key) => {
      return get(
        value,
        `house_function.${ctx.root.$store.state.locale.currentLang}.house_function_${key}`,
      )
    }

    const submit = (validate) => {
      const callback = () => {
        validate().then(async (isValid) => {
          if (!isValid) {
            notifyFn.error({
              text: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง'
            })
            return
          }

          ctx.root.$vs.loading()

          const payload = buildPayload()

            ctx.root.$store
            .dispatch(ctx.root.$route.params.uuid ? 'projectHouseSell/updateItem' : 'projectHouseSell/addItem', payload)
            .then(() => {
              ctx.root.$router.push({
                name: 'property-asset-project-home-sell',
                params: {
                  project_id: ctx.root.$route.params.project_id,
                },
              })

              notifyFn.success({
                text: 'แก้ไขข้อมูลเรียบร้อยแล้ว'
              })

              ctx.root.$vs.loading.close()
            })
            .catch((e) => {
              notifyFn.error({ text: e })
              ctx.root.$vs.loading.close()
            })
        })
      }

      if (ctx.root.$route.params.uuid) {
        return notifyFn.confirm({ callback })
      }

      return callback()
    }

    const goToPreview = () => {
      const lang = ctx.root.$store.state.locale.currentLang
      const websiteEndpoint = env.VUE_APP_WEBSITE_URL
      const projectLink = get(ctx.root.$store.state.project, 'project.project_category_links.0.url')
      const projectHouseSellLink = get(ctx.root.$store.state.projectHouseSell, `item.house_model.${lang}.house_model_title`)
      const projectHouseSellNumber = get(ctx.root.$store.state.projectHouseSell, `item.${lang}.project_house_sell_number`)

      window.open(`${websiteEndpoint}/${lang}/${projectLink}/homesell/${projectHouseSellLink}/${projectHouseSellNumber}`)
    }

    onMounted(() => {
      onload()
      // console.log(ctx.root.$route.meta.breadcrumb)
      if (ctx.root.$route.meta.breadcrumb) {
        ctx.root.$route.meta.breadcrumb = ctx.root.$route.meta.breadcrumb.map((item) => {
          if (!item.url) {
            return item
          }

          return {
            ...item,
            url: item.url.replace(':project_id', ctx.root.$route.params.project_id),
          }
        })

        ctx.root.$store.dispatch('updateBreadcrumbData', [
          { title: 'Home', url: '/' },
          { title: 'Project', url: `/property-asset/project/edit/${ctx.root.$route.params.project_id}` },
          {
            title: 'Home Sell List',
            url: `/property-asset/project/${ctx.root.$route.params.project_id}/home-sell`,
          },
          { title: 'Create Home Sell', active: true },
        ])
      }

    })

    onBeforeUnmount(() => {
      ctx.root.$store.dispatch('updateBreadcrumbData', [])
    })


    const formatDate = (date) => {
      return date ? moment(date).format('LLL') : ''
    }

    const lifeTimeDuration = env.VUE_APP_PROJECT_HOUSE_SELL_LIFE_TIME_DURATION
    const lifeTimeUnit = env.VUE_APP_PROJECT_HOUSE_SELL_LIFE_TIME_UNIT

    const maxEndDate = computed(() => {
      const max = moment(formData.project_house_sell_start_date).add(lifeTimeDuration, lifeTimeUnit).format()
      const min = moment(formData.project_house_sell_start_date).subtract(1, 'd').format()
      
      return { to: new Date(min), from: new Date(max) }
    })

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)

    const isEditor = computed(() => ctx.root.$acl.check('editor'))

    const setSelected = () => {
      formData.project_house_sell_gallery_by_project_selected = []
    }

    const isExpired = computed(() => {
      const today = moment().format()
      const startDate = formData.project_house_sell_start_date
      const soldEndDate = formData.project_house_sell_sold_out_end_date
      const endDate = formData.project_house_sell_end_date

      console.log('formData: ', formData)

      if (formData && formData.show_list_page && !formData.is_sold_out) return false
      if (formData.is_sold_out) return !moment(today).isBetween(startDate, soldEndDate, 'day', '[]')
      return !moment(today).isBetween(startDate, endDate, 'day', '[]')
    })
  
    return {
      formData,
      modelList,
      houseFunctionList,
      houseIconActivityList,
      houseModelArea,
      houseIconActivitySelected,
      houseFeatureFunctionList,
      houseSeriesLogo,
      houseSellGallery,
      houseFloors,
      houseModelPublicGallery,
      houseSellGalleryList,
      houseSellGalleryByProject,
      getHouseFunction,
      submit,
      goToPreview,
      projectId,
      baseFolder,
      formatDate,
      isEditor,
      getImageUrl,
      customFormatter,
      maxEndDate,
      pageTitle,
      setSelected,
      isExpired
    }
  },
}
</script>

<style scoped></style>
