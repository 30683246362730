var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"pb-8"},[_vm._v("ระบุฟังก์ชันของบ้าน")]),_vm._l((_vm.houseFeatureFunctionList),function(item,index){return _c('FormLayout',{key:index},[_c('FormLabel',[_vm._v("\n      "+_vm._s(item.house_function_title)+"\n    ")]),_c('FormContent',{staticClass:"flex space-x-3 items-center"},[_c('vs-input',{attrs:{"value":item.value,"name":"","disabled":""}}),_c('span',[_vm._v("\n        "+_vm._s(_vm.getHouseFunction(item, 'unit'))+"\n      ")])],1)],1)}),_c('h4',{staticClass:"pb-8"},[_vm._v("ระบุรายละเอียดเพิ่มเติม (ถ้ามี)")]),_c('FormLayout',[_c('FormLabel'),_c('FormContent',{staticClass:"flex flex-col space-y-3"},_vm._l((_vm.featureList),function(item,index){return _c('vs-checkbox',{key:index,attrs:{"name":("project_house_sell_features_" + index),"vs-value":item.id},model:{value:(_vm.$attrs.value.project_house_sell_features_selected),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "project_house_sell_features_selected", $$v)},expression:"$attrs.value.project_house_sell_features_selected"}},[_vm._v("\n        "+_vm._s(item.house_function_feature_title)+"\n      ")])}),1)],1),_c('FormLayout',[_c('FormLabel',[_vm._v("\n      ภาพ ICON ซีรี่ส์แบบบ้าน\n    ")]),_c('FormContent',[_c('vx-card',{style:({
          minHeight: '150px',
        })},[(_vm.houseSeriesLogo && _vm.houseSeriesLogo.image)?_c('img',{staticClass:"rounded",attrs:{"src":_vm.getImageUrl(_vm.houseSeriesLogo.image.public_id),"alt":"house-series-logo","width":"150px"}}):_vm._e()])],1)],1),_c('FormLayout',[_c('FormLabel',[_vm._v("\n      ภาพ แบบบ้าน\n      "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('br'),_vm._v("(เลือกรูปแบบบ้านหลัก) "),_c('br'),_vm._v("เลือกรูปอย่างน้อย 1 รูป\n    ")]),_c('div',{staticClass:"vx-col w-full md:w-9/12"},[_c('ValidationProvider',{attrs:{"name":"ภาพ แบบบ้าน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('vx-card',{style:({
            minHeight: '150px',
          })},[_c('div',{staticClass:"grid grid-cols-4 gap-3"},_vm._l((_vm.houseSellGallery),function(item,index){return _c('div',{key:index,staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"mb-4"},[_c('img',{staticClass:"rounded w-full",attrs:{"src":_vm.getImageUrl(item.public_id),"alt":"house-series-logo"}})]),_c('vs-radio',{attrs:{"vs-value":item.uuid,"vs-name":"project_house_sell_gallery_selected"},model:{value:(_vm.$attrs.value.project_house_sell_gallery_selected),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "project_house_sell_gallery_selected", $$v)},expression:"$attrs.value.project_house_sell_gallery_selected"}},[_vm._v("\n                เลือกเป็นภาพหลัก\n              ")])],1)}),0)]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1),_vm._l((_vm.floors),function(content,index){return _c('div',{key:("house-floor-" + index)},[_c('FormLayout',[_c('FormLabel',[_vm._v("\n        ภาพ Floor Plan ชั้น "+_vm._s(content.no)+"\n      ")]),_c('FormContent',[_c('vx-card',{style:({
            minHeight: '150px'
          })},[_c('img',{staticClass:"rounded w-full",attrs:{"src":_vm.getImageUrl(content.image.public_id),"alt":"house-floors-image"}})])],1)],1),_c('FormLayout',[_c('FormLabel',[_vm._v("\n        รายละเอียด ชั้น "+_vm._s(content.no)+"\n      ")]),_c('FormContent',[_c('vs-textarea',{staticClass:"disable-area",attrs:{"value":content.description,"rows":8,"disabled":""}})],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }