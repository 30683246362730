var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormLayout',[_c('FormLabel',[_vm._v(" เลือกชื่อแบบบ้าน "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"เลือกชื่อแบบบ้าน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.modelList,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"placeholder":"เลือกชื่อแบบบ้าน"},model:{value:(_vm.$attrs.value.house_model),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "house_model", $$v)},expression:"$attrs.value.house_model"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_vm._v("\n      หมายเลขแปลง\n      "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"หมายเลขแปลง","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"disabled":_vm.isEditMode,"name":"project_house_sell_number"},model:{value:(_vm.$attrs.value.project_house_sell_number),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "project_house_sell_number", $$v)},expression:"$attrs.value.project_house_sell_number"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_vm._v("\n       กำหนดสถานะ sold out\n      "),_c('br')]),_c('FormContent',[_c('vs-checkbox',{staticClass:"mt-2",attrs:{"name":"project_house_sell_files_check_box"},model:{value:(_vm.$attrs.value.is_sold_out),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "is_sold_out", $$v)},expression:"$attrs.value.is_sold_out"}},[(_vm.$attrs.value.is_sold_out)?_c('span',{staticClass:"text-warning"},[_vm._v("แสดงบนหน้าเว็บไซต์ถึงวันที่ "+_vm._s(_vm.showInWebsiteDate))]):_vm._e()])],1)],1),_c('FormLayout',[_c('FormLabel',[_vm._v("\n      ระบุลักษณะเด่นของแปลง "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('br'),_c('span',[_vm._v("(ไม่เกิน 250 ตัวอักษร)")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ระบุลักษณะเด่นของแปลง","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{attrs:{"name":"project_house_sell_highlight","counter":"250","maxlength":"250","rows":6},model:{value:(_vm.$attrs.value.project_house_sell_highlight),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "project_house_sell_highlight", $$v)},expression:"$attrs.value.project_house_sell_highlight"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_vm._v("\n      สร้างบนพื้นที่ตั้งแต่ "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"สร้างบนพื้นที่ตั้งแต่","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex space-x-3 items-center"},[_c('lh-input-number',{staticClass:"w-3/12",attrs:{"name":"project_house_sell_building_space"},model:{value:(_vm.$attrs.value.project_house_sell_building_space),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "project_house_sell_building_space", $$v)},expression:"$attrs.value.project_house_sell_building_space"}}),_c('span',[_vm._v("ตารางวา")])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_vm._v("\n      พื้นที่ใช้สอย\n    ")]),_c('FormContent',{staticClass:"flex space-x-3 items-center"},[_c('vs-input',{staticClass:"w-3/12",attrs:{"value":_vm.houseModelArea,"disabled":"","readonly":""}}),_c('span',[_vm._v("ตารางเมตร")])],1)],1),_c('FormLayout',[_c('FormLabel',[_vm._v("\n      ระบุไอคอนกิจกรรม (ถ้ามี)\n    ")]),_c('FormContent',{staticClass:"flex flex-col space-y-3"},[_c('v-select',{attrs:{"options":_vm.houseIconActivityList,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"placeholder":"เลือกไอคอน"},model:{value:(_vm.$attrs.value.project_house_sell_icon_activity),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "project_house_sell_icon_activity", $$v)},expression:"$attrs.value.project_house_sell_icon_activity"}}),_c('vx-card',[(
            _vm.$attrs.value.project_house_sell_icon_activity &&
              _vm.$attrs.value.project_house_sell_icon_activity.image
          )?_c('img',{staticClass:"border-2 border-danger",attrs:{"src":_vm.getImageUrl(_vm.$attrs.value.project_house_sell_icon_activity.image.public_id),"alt":"image-activity"}}):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }