<template>
  <div>
    <h4 class="pb-8">ระบุฟังก์ชันของบ้าน</h4>
    <FormLayout 
      v-for="(item, index) in houseFeatureFunctionList" 
      :key="index">
      <FormLabel>
        {{ item.house_function_title }}
      </FormLabel>
      <FormContent class="flex space-x-3 items-center">
        <vs-input 
          :value="item.value" 
          name="" 
          disabled />
        <span>
          {{ getHouseFunction(item, 'unit') }}
        </span>
      </FormContent>
    </FormLayout>
    <h4 class="pb-8">ระบุรายละเอียดเพิ่มเติม (ถ้ามี)</h4>
    <FormLayout>
      <FormLabel />
      <FormContent class="flex flex-col space-y-3">
        <vs-checkbox
          v-for="(item, index) in featureList"
          :key="index"
          v-model="$attrs.value.project_house_sell_features_selected"
          :name="`project_house_sell_features_${index}`"
          :vs-value="item.id"
        >
          {{ item.house_function_feature_title }}
        </vs-checkbox>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        ภาพ ICON ซีรี่ส์แบบบ้าน
      </FormLabel>
      <FormContent>
        <vx-card
          :style="{
            minHeight: '150px',
          }"
        >
          <img
            v-if="houseSeriesLogo && houseSeriesLogo.image"
            :src="getImageUrl(houseSeriesLogo.image.public_id)"
            alt="house-series-logo"
            width="150px"
            class="rounded"
          >
        </vx-card>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        ภาพ แบบบ้าน
        <span class="text-danger">*</span>
        <br >(เลือกรูปแบบบ้านหลัก) <br >เลือกรูปอย่างน้อย 1 รูป
      </FormLabel>
      <div class="vx-col w-full md:w-9/12">
        <ValidationProvider
          v-slot="{ errors }"
          name="ภาพ แบบบ้าน"
          rules="required"
        >
          <vx-card
            :style="{
              minHeight: '150px',
            }"
          >
            <div class="grid grid-cols-4 gap-3">
              <div
                v-for="(item, index) in houseSellGallery"
                :key="index"
                class="flex flex-col w-full"
              >
                <div class="mb-4">
                  <img
                    :src="getImageUrl(item.public_id)"
                    alt="house-series-logo"
                    class="rounded w-full"
                  >
                </div>
                <vs-radio
                  v-model="$attrs.value.project_house_sell_gallery_selected"
                  :vs-value="item.uuid"
                  vs-name="project_house_sell_gallery_selected"
                >
                  เลือกเป็นภาพหลัก
                </vs-radio>
              </div>
            </div>
          </vx-card>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
    </FormLayout>
    <div
      v-for="(content, index) in floors"
      :key="`house-floor-${index}`"
    >
      <FormLayout>
        <FormLabel>
          ภาพ Floor Plan ชั้น {{ content.no }}
        </FormLabel>
        <FormContent>
          <vx-card
            :style="{
              minHeight: '150px'
            }"
          >
            <img
              :src="getImageUrl(content.image.public_id)"
              alt="house-floors-image"
              class="rounded w-full"
            >
          </vx-card>
        </FormContent>
      </FormLayout>
      <FormLayout>
        <FormLabel>
          รายละเอียด ชั้น {{ content.no }}
        </FormLabel>
        <FormContent>
          <vs-textarea 
            class="disable-area"
            :value="content.description" 
            :rows="8"
            disabled />
        </FormContent>
      </FormLayout>
    </div>
    <!-- <HouseSellFloorForm
      v-for="(content) in floors"
      :content="content"
      :key="`house-floor-${get(content, ['image', 'uuid'])}}`"
    /> -->
  </div>
</template>

<script>
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import { getImageUrl } from '@/helper/Utils'
import HouseSellFloorForm from '@/views/apps/property-asset/project-home-sell/HouseSellFloorForm'
import { computed } from '@vue/composition-api'

export default {
    name: 'HouseFunctionForm',
    components: {
       HouseSellFloorForm
    },
    props: {
      houseFeatureFunctionList: {
          type: Array,
          required: true,
      },
      houseSeriesLogo: {
          type: Object,
          required: true,
      },
      houseSellGallery: {
          type: Array,
          required: true
      },
      houseFloors: {
          type: Array,
          required: true
      }
    },
    setup(props, ctx) {
      const getHouseFunction = (value, key) => {
        return get(
            value,
            `house_function.${ctx.root.$store.state.locale.currentLang}.house_function_${key}`,
        )
      }

      const featureList = computed(() => {
        const list = ctx.root.$store.state.houseFunctionFeature.items
        return orderBy(list, 'created_at', 'asc')
      })

      const floors = computed(() => {
        return props.houseFloors.filter((floor) => floor)
      })

      return {
        getHouseFunction,
        getImageUrl,
        floors,
        get,
        featureList
      }
    }
}
</script>
